<template>
  <div>
    <h2 class="ui-kit__heading">
      Платежные документы
    </h2>
    <ItemCard
      v-for="(invoice, i) in typeInvoices"
      v-show="info.final_prepaid || invoice.type !== 'prepaid'"
      :key="i"
      :is-edit="isEdit === invoice.type"
      @click.native="isEditCost(invoice.type)"
    >
      <v-layout wrap align-center fill-height>
        <v-flex xs12>{{ invoice.label }}</v-flex>
      </v-layout>
      <template slot="buttons">
        <custom-button flat color="primary" class="my-0">
          <icon name="pdf" size="18" />
        </custom-button>
      </template>
      <v-layout slot="content" row wrap>
        <v-flex xs4>
          <custom-input v-model="newValue" label="Сумма руб." money="integer" placeholder="0" />
        </v-flex>
        <v-flex xs12 class="mb-3">
          <custom-button
            large
            round
            color="white"
            class="ml-0"
            @click.stop="downloadInvoice({ type: invoice.type, newCost: true })"
          >
            Продолжить
          </custom-button>
          <custom-button flat color="primary" @click.stop="isEdit = null">
            Отмена
          </custom-button>
        </v-flex>
      </v-layout>
    </ItemCard>
  </div>
</template>

<script>
/* eslint-disable camelcase */
import { mapState, mapGetters, mapActions } from 'vuex';

import { formattingDate } from 'common/helpers/dateHelper';
import { invoicePayment } from 'common/helpers/pdf';

import ItemCard from 'common/components/Orders/ItemCard';
export default {
  name: 'CarrierDocumentsPayments',
  components: {
    ItemCard
  },
  props: {
    shipper: {
      type: Object,
      required: true,
      validator(value) {
        return !!Object.keys(value).length;
      }
    },
    carrier: {
      type: Object,
      required: true,
      validator(value) {
        return !!Object.keys(value).length;
      }
    }
  },
  data() {
    return {
      isEdit: null,
      newValue: ''
    };
  },
  computed: {
    ...mapGetters('orders', ['getFullRoute']),
    ...mapState('orders', {
      info: state => state.orderInfo.info
    })
  },
  created() {
    this.typeInvoices = [
      {
        type: 'prepaid',
        label: 'Счет на аванс'
      },
      { type: 'cost', label: 'Счет' },
      { type: 'act', label: 'Акт выполненных работ' }
    ];
  },
  methods: {
    ...mapActions('common', ['createController']),

    isEditCost(type) {
      this.isEdit = type;
      this.newValue = this.getCost(type);
    },

    async downloadInvoice({ type, newCost = false }) {
      const { nds } = await this.createController({
        topic: 'order/nds_for_accepted_order',
        json: { cid: this.info.cid }
      });

      const content = {
        number: this.info.id,
        date: formattingDate(this.info.accept_datetime),
        typeInvoice: type,
        shipper: {
          ...this.shipper,
          fullInfo: this.fullInfo(this.shipper),
          boss: this.getBoss('shipper')
        },
        carrier: {
          ...this.carrier,
          fullInfo: this.fullInfo(this.carrier),
          boss: this.getBoss('carrier'),
          nds
        },
        fullRoute: this.getFullRoute,
        cost: newCost ? this.newValue : this.getCost(type)
      };
      const name = this.typeInvoices.find(t => t.type === type)?.label ?? 'Файл';
      const pdf = invoicePayment({ content, name });
      this.isEdit = null;
      pdf.download(`${name}_${this.info.id}.pdf`);
    },

    fullInfo(data) {
      let info;
      if (data.yur_type === 'FL') {
        info = `${data.name}, дата рождения ${formattingDate(data.birthday_date)}, ИНН ${
          data.inn
        }, паспорт серия ${data.passport_serie}, номер ${
          data.passport_number
        }, дата выдачи ${formattingDate(data.passport_date)}, ${data.accounts[0]?.bank ??
          '___'}, р\\с ${data.accounts[0]?.number ?? '___'}, к\\с ${data.accounts[0]?.korr_number ??
          '___'}`;
      } else if (data.yur_type === 'IP') {
        info = `${data.name}, ИНН ${data.inn}, ОГРНИП ${data.ogrn}, ${data.legal_zip ||
          '________'} ${data.legal_address || '___________________________'}, ${
          data.accounts[0]?.bank
        }, р\\с ${data.accounts[0]?.number ?? '___'}, к\\с ${data.accounts[0]?.korr_number ??
          '___'}, тел. ${data.phone}`;
      } else {
        info = `${data.name}, ИНН ${data.inn}, ОГРН ${data.ogrn}, ${data.legal_zip ||
          '________'} ${data.legal_address || '___________________________'}, ${data.accounts[0]
          ?.bank ?? '___'}, р\\с ${data.accounts[0]?.number ?? '___'}, к\\с ${data.accounts[0]
          ?.korr_number ?? '___'}, тел. ${data.phone}`;
      }
      return info;
    },

    getBoss(type) {
      let boss;
      if (this[type].yur_type === 'FL') {
        boss = this[type].name;
      } else if (this[type].yur_type === 'IP') {
        const [abrr] = ['ИП', 'Индивидуальный предприниматель'].filter(_ =>
          this[type].name.includes(_)
        );
        boss = this[type].name.replace(abrr, 'Индивидуальный предприниматель: ').trim();
      } else boss = `Генеральный директор: ${this[type].ceo}`;
      return boss;
    },

    getCost(type) {
      if (type === 'prepaid') {
        return this.info.final_prepaid.toLocaleString('ru');
      }

      if (type === 'cost') {
        return (this.info.final_cost - this.info.final_prepaid).toLocaleString('ru');
      }

      return this.info.final_cost.toLocaleString('ru');
    }
  }
};
</script>
